@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lexend:wght@600&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@600&display=swap");
html,
body,
#root {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden !important;
}

*{
  overflow: visible !important;
}


a{
  text-decoration: none;
}

img {max-width:100%;}

#appbar-desktop{
  margin-top: 100px;
}

#appbar-mobile{
  display: none;
}



.App {
  text-align: center;
}

.just-absolute{
  position: absolute;
}

.header-mark{
  right: 0px;
  top: -30px;
}

.absolute{
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
}

.slider-images{
  display: flex;
  justify-content: center;
}

.slider-image{
  position: relative;
  z-index: 100;
}

.App-logo {
  pointer-events: none;
}

.MuiSlider-thumb{
  color: white;
  border: 2px solid #443538
}

.shadowImg {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.MuiButtonBase-root {
  border-radius: 30px !important;
  padding: 5px 35px !important;
  color: #443538 !important;
  border-color: #443538 !important;
  text-transform: lowercase !important;
} 

.css-qx5f9e-MuiSlider-rail{
  opacity: 100% !important;
}

.nospace{
  white-space: nowrap;
}

.desktop{
  display: block;
}

.mobile{
  display: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.map{
  position: relative;
  border-radius: 38px;
  -webkit-transform: translate3d(0px, 0px, 0px);
  border: black solid 2px;
  width:100%;
  z-index: 5;
}

.d-inline{
  display: inline-block;
}

.center{
  text-align: center;
}

.relative{
  position: relative;
}

#company-mark{
  right: 0px;
  top: 0;
  z-index: 0;
}

/* company */
.container {
  display: flex;
  justify-content: space-between;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.line {
  border-bottom: 1px solid #000;
  margin: 5px 0;
}

.display-sm{
  display: none;
}

.mb-3{
  margin-bottom: 2rem;
}

@media screen and (max-width: 900px) {
  .header-mark{
    display: none;
  }
}

@media screen and (max-width: 1199px) {

  *{
    overflow: visible !important;
  }

  .title{
    justify-content: center !important;
    font-size: 48px !important;
  }

  .div-center-md{
    text-align: center !important;
  }

  .mobile{
    display: block;
  }

  .desktop{
    display: none;
  }

  .pr-16-md{
    padding-right: 16px;
  }

  .mobile-pl-40{
    padding-right: 40px;
  }

  .descrption-mobile{
    max-width: 300px;
    margin: 20px auto;
  }

  .display-md{
    display: block;
  }

  .display-sm{
    display: none;
  }
}

@media screen and (max-width: 899px){
  .display-md{
    display: none;
  }

  .display-sm{
    display: block;
  }

  .center-sm{
    text-align: center !important;
  }
  
  #phone{
    margin-left: 4.5rem;
  }

}

@media screen and (max-width: 600px) {
  html,
body,
#root {
  height: 100%;
  overflow: visible;
}
  #appbar-desktop{
    display: none;
  }
  #appbar-mobile{
    display: block;
    margin-top: 50px;
  }

  .slider-images{
  display: block;
  justify-content: center;
  padding-left: 0px;
}

  .MuiButtonBase-root{
    padding: 15px !important;
  }

  .slider-image{
    margin-top: 2rem !important;
  }

  #phone{
    width: 250px;
    margin-left: 2.5rem;
  }

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
